import { orderBy, kebabCase } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import spTemplates from 'assets/sp-attack-lib/sp-attack-templates.json'

import { config } from 'global/lib/config'
import * as configurationLib from 'global/lib/configuration/configuration'
import { AccessToken } from 'global/types/api/accessTokenType'
import * as datetime from 'global/lib/datetime'
import { IdentityDetails } from 'global/types/api/identity'

export interface Attack {
  attackType: string
  body: {
    content: string
    contentType: string
  }
  id: string
  senderName?: string
  subject: string
  templateName: string
  type: string
}

type PatternParser = [string | RegExp, (args: string | number[]) => string]

export interface ParserConfig {
  [key: string]: any
  recipient: IdentityDetails | undefined
  sender: IdentityDetails | undefined
  accessToken: AccessToken
}

export const SP_ATTACK_TEMPLATES: Attack[] = orderBy(spTemplates.templates, 'attackType')

export function getSpLink(name: string, type: string): string {
  return `http://${configurationLib.getTrainingDomain()}/training/sentinel-sim-page/${type}/${uuidv4()}/${kebabCase(
    name
  )}`
}

/* eslint-disable @typescript-eslint/naming-convention */
export function parseTemplateValues(attack: Attack, parserConfig: ParserConfig): Attack {
  if (!attack) {
    return attack
  }

  const PATTERN_PARSERS: PatternParser[] = [
    [
      /\{\{recipient.(.*?)\}\}/g,
      args => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, prop] = args

        return parserConfig.recipient?.[prop]
      }
    ],
    [
      /\{\{sender.(.*?)\}\}/g,
      args => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, prop] = args

        return parserConfig.sender?.[prop]
      }
    ],
    [
      /\{\{accessToken.(.*?)\}\}/g,
      args => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, prop] = args

        return (parserConfig.accessToken as any)[prop]
      }
    ],
    [
      /\{\{date.(.*?)\}\}/g,
      args => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, dateFormat] = args
        if ((dateFormat as string).includes('APP_CONFIG')) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [__, dateFormatConstant] = (dateFormat as string).split('.')

          return datetime.formatDate(undefined, config.DATETIME[dateFormatConstant])
        }

        // eslint-disable-next-line quotes
        return datetime.formatDate(undefined, (dateFormat as string).replace("'", '')).trim()
      }
    ],
    [
      /\{\{getSpLink,\s(.*?),\s'(.*?)'\}\}/g,
      args => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, propPath, type] = args
        const [configProp, configSubProp]: string[] = (propPath as string).split('.')
        const userName = (parserConfig[configProp] || {})[configSubProp] || ''

        return getSpLink(userName, type as string)
      }
    ]
  ]
  /* eslint-enable @typescript-eslint/naming-convention */

  function parseProperty(prop: string) {
    return PATTERN_PARSERS.reduce((parsedProp: string, patternParser: PatternParser) => {
      const [pattern, parserMethod] = patternParser

      return parsedProp.replace(pattern, (...args) => parserMethod(args) || '')
    }, prop)
  }

  return {
    ...attack,
    subject: parseProperty(attack.subject),
    body: {
      ...attack.body,
      content: parseProperty(attack.body.content)
    }
  }
}
