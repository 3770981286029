import React, { useMemo } from 'react'

import { Link, Typography, Grid } from '@barracuda-internal/bds-core'
import { EpImpersonationProtection } from '@barracuda-internal/bds-core/dist/Logos/Products'

import styles, { SINGLE_COLUMN_WRAPPER_WIDTH } from 'global/components/features/signinSignup/signinSignupStyles'
import Loading from 'global/components/lib/loading/Loading'

import { useFormatMessage } from 'global/lib/localization'

import SigninSignupLayout from 'sen/components/lib/layout/SigninSignupLayout'
import useSignupLogic from 'sen/components/pages/signup/signinSignup/useSigninSignupLogic'

const BASE_I18N_KEY = 'sen.app.login'

const Signup: React.FC = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [signinSignupLogic] = useSignupLogic()

  return useMemo(() => {
    const {
      error,
      isLoading,
      isPermissionDialogVisible,
      onStartTrial,
      onTogglePermissionDialog,
      requestDemoPath,
      salesLeadEpId,
      sendDemoRequestTrackingEvent
    } = signinSignupLogic

    if (isLoading) {
      return <Loading />
    }

    return (
      <SigninSignupLayout error={error}>
        <SigninSignupLayout.Wrapper isFormInProgress={false} style={{ width: `${SINGLE_COLUMN_WRAPPER_WIDTH}px` }}>
          <SigninSignupLayout.Header>
            <Grid container item>
              <EpImpersonationProtection alt="Impersonation Protection" />
            </Grid>
          </SigninSignupLayout.Header>
          <SigninSignupLayout.Content>
            <Grid container className={classes.infoContent}>
              <Grid item xs={12}>
                <Typography className={classes.headingTitleSingleColumn}>
                  {formatMessage('barracuda_product')}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.infoContent}>
              <Grid item xs={12}>
                <Typography className={classes.headingSubTitle}>{formatMessage('product_blurb')}</Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.infoContent}>
              <Grid item xs={12}>
                <Typography align="left">{formatMessage('signup_description')}</Typography>
              </Grid>
            </Grid>
            <SigninSignupLayout.Connect
              appName={formatMessage('barracuda_product')}
              isPermissionDialogVisible={isPermissionDialogVisible}
              onStartTrial={onStartTrial}
              onTogglePermissionDialog={onTogglePermissionDialog}
              permissionDialogtitle={formatMessage('form.permission_dialog_title')}
              requestDemoPath={requestDemoPath}
              salesLeadEpId={salesLeadEpId}
              sendDemoRequestTrackingEvent={sendDemoRequestTrackingEvent as any}
            />

            <Grid container className={classes.formSigninWrapperSingleColumn}>
              <Grid item xs={12}>
                <Typography align="center" data-field="form-title">
                  {formatMessage('form.already_using', {
                    signin: (txt: string) => (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <Link key={txt} color="primary" data-action="signin" className={classes.formBlue}>
                        {txt}
                      </Link>
                    )
                  })}
                </Typography>
              </Grid>
            </Grid>
          </SigninSignupLayout.Content>
        </SigninSignupLayout.Wrapper>
      </SigninSignupLayout>
    )
  }, [classes, formatMessage, signinSignupLogic])
}

export default Signup
