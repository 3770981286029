import { cloneDeep } from 'lodash'
import React, { useCallback, useEffect, useMemo, useReducer } from 'react'
import { useCookies } from 'react-cookie'

import colors from '@barracuda-internal/bds-core/dist/styles/colors'
import { process, SortDescriptor } from '@progress/kendo-data-query'
import { GridDetailRow } from '@progress/kendo-react-grid'

import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'
import {
  getMoreUserReportedGroupedEmails,
  getUserReportedGroupedEmails,
  resetUserReportedFailures,
  resetUserReportedGroupedEmails,
  updateUserReportedState,
  runPowershell
} from 'fir/redux/features/userReported/userReportedSlice'
import {
  reset as dataTableReset,
  update as dataTableUpdate,
  DISMISSED_STATE,
  SUBMITTED_STATE
} from 'fir/redux/features/dataTables/userReported/userReportedSlice'
import { getUserReportedTopReportersStats } from 'fir/redux/features/stats/statsSlice'
import { UserSubmittedEmailGrouped, SubmittedSearchCriteria, SearchCriteria } from 'fir/redux/types/UserReported'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import EmailDetails from 'global/components/lib/dialogs/emailDetailsDialog/components/emailDetails/EmailDetails'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { config } from 'global/lib/config'
import * as datetime from 'global/lib/datetime'
import { useFormatMessage } from 'global/lib/localization'
import useProductLib from 'global/lib/product/useProduct'
import toggleInArray from 'global/lib/toggleInArray'
import { MultiSeriesTooltip } from 'global/components/lib/charts/chartTooltips/MultiSeriesTooltip'
import { getErrorMessage, isFailed, isPending, isSuccess } from 'global/redux/toolkit/api'
import { IncidentDetailsSource } from 'global/types/api/newIncident'
import { AlertProps } from 'global/components/lib/alerts/Alert'

import { NewIncidentDialogProps } from 'fir/components/lib/newIncidentDialog/NewIncidentDialog'
import { TopReportedDashboardProps } from 'fir/components/pages/userReported/dashboards/TopReportersDashboard'
import useEmailDetailsInterface, {
  collectRecipients
} from 'fir/components/lib/dialogs/emailDetailsDialog/useEmailDetailsInterface'
import { USER_REPORTED_CTA_COOKIE } from 'fir/components/lib/ctaBanner/useCTABannerLogic'

const BASE_I18N_KEY = 'fir.app.user_reported'
const GLOBAL_BASE_I18N_KEY = 'app.error'

export interface ExpandConfig {
  expandField: string
  detail: (cellProps: any) => JSX.Element | null
  onExpandChange: (e: any) => void
}

export interface GridRow {
  emailId: string
  expandedRowContent: any | null
  formattedDate: string
  isPhishline: boolean
  lastReported: string
  maliciousTooltipMessage: string | undefined
  maxMatchedCount: number
  messageIds: string[]
  numberOfUserReported: number
  recipients: []
  searchCriteria: SearchCriteria
  sender: string
  senderEmail: string
  subject: string
}

export interface PageConfig {
  skip: number
  take: number
  total: number
  onPageChange: (e: any) => void
}

export interface Sender {
  address: string
  name: string
}

export interface SortConfig {
  sort: SortDescriptor[]
  onSortChange: (e: any) => void
}

export interface UserReportedProps {
  alertConfig: AlertProps
  rowsDismissed?: string[]
  basicIncidentResponse: boolean
  columnsConfig: any
  expandConfig: ExpandConfig
  GRID_COLUMNS: any
  gridData: any
  hideCTA: boolean
  isDismissRowLoading: boolean
  isMoreResultsLoading: boolean
  isPageInProgress: boolean
  pageConfig: PageConfig
  showErrorAlert: boolean
  sortConfig: SortConfig
  topReportersDashboardConfig: TopReportedDashboardProps
  viewingState: string
  newIncidentDialogConfig: NewIncidentDialogProps
  onCreateIncident: (searchParams: SubmittedSearchCriteria) => void
  onDismissUserReported: (emailId: string, messageIds: string[]) => void
  onToggleViewState: () => void
  // TODO: Remove this after BNFIR-4168 test
  runPowershellzz: () => void
}

const EXPAND_FIELD = 'expandField'

export default function useUserReportedLogic(): [UserReportedProps] {
  const [cookies] = useCookies([USER_REPORTED_CTA_COOKIE])
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const globalFormatMessage = useFormatMessage(GLOBAL_BASE_I18N_KEY)
  const dispatch = useAppDispatch()
  const [productLib] = useProductLib()
  const [emailDetailDialogConfig, emailDetailDialogActions] = useEmailDetailsInterface({})

  // Redux Toolkit stores
  const {
    accessTokenId,
    emails,
    isEmailInfoFailed,
    isEmailInfoLoading,
    satCampaignsIgnored,
    stats,
    userReported,
    userReportedTable
  } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id || '',
    emails: _stores.emails,
    isEmailInfoFailed: isFailed(_stores.emails.emailInfoLoadingApiStatus),
    isEmailInfoLoading: isPending(_stores.emails.emailInfoLoadingApiStatus),
    satCampaignsIgnored: _stores.settings.forensics.forensicsUserReportedIgnoreSatCampaigns,
    stats: _stores.stats,
    userReported: _stores.userReported,
    userReportedTable: _stores.dataTables.userReported
  }))

  // userReported state
  const {
    isGetUserReportedGroupedEmailsSuccess,
    isUpdateUserReportedStateSuccess,
    isGetUserReportedGroupedEmailsFailed,
    isGetMoreUserReportedGroupedEmailsFailed,
    isUpdateUserReportedStateLoading,
    isGetMoreUserReportedGroupedEmailsLoading,
    isUserReportedLoading,
    totalCount,
    userReportedEmailsData,
    updateUserReportedErrorMsg
  } = {
    isUserReportedLoading: isPending(userReported.getUserReportedGroupedEmailsAPIStatus),
    isGetUserReportedGroupedEmailsSuccess: isSuccess(userReported.getUserReportedGroupedEmailsAPIStatus),
    isUpdateUserReportedStateSuccess: isSuccess(userReported.updateUserReportedStateAPIStatus),
    isGetUserReportedGroupedEmailsFailed: isFailed(userReported.getUserReportedGroupedEmailsAPIStatus),
    isGetMoreUserReportedGroupedEmailsFailed: isFailed(userReported.getMoreUserReportedGroupedEmailsAPIStatus),
    isUpdateUserReportedStateLoading: isPending(userReported.updateUserReportedStateAPIStatus),
    isGetMoreUserReportedGroupedEmailsLoading: isPending(userReported.getMoreUserReportedGroupedEmailsAPIStatus),
    totalCount: userReported.userReportedEmails.totalCount,
    updateUserReportedErrorMsg: getErrorMessage(userReported.updateUserReportedStateAPIStatus),
    userReportedEmailsData: userReported.userReportedEmails.data
  }

  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    errorMsg: '',
    newIncidentSearchCriteria: null,
    rowsDismissed: [],
    reportedState: SUBMITTED_STATE
  })

  // Helpers
  const apiParams = useMemo(() => {
    const { page, sort } = userReportedTable

    return {
      states: [state.reportedState],
      query: {
        filter: [],
        limit: Number(page.take),
        page: Math.abs(Number(page.skip) / Number(page.take)) + 1,
        order: `${sort[0].dir === 'asc' ? '' : '-'}${sort[0].field}`
      }
    }
  }, [state.reportedState, userReportedTable])

  // Init
  useEffect(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.USER_REPORTED_VIEWED, {
      accessTokenId,
      page: 'user-reported'
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Handle init & reload after toggling reportedState
  useEffect(() => {
    const resetApiParams = apiParams
    resetApiParams.query.page = 1
    dispatch(getUserReportedGroupedEmails({ config: resetApiParams }))
    dispatch(getUserReportedTopReportersStats({ accessTokenId }))

    return () => {
      dispatch(resetUserReportedGroupedEmails())
      dispatch(dataTableReset())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.reportedState])

  // Periodic refresh
  useEffect(() => {
    const refreshInterval = setInterval(() => {
      if (
        isGetUserReportedGroupedEmailsSuccess &&
        userReportedTable.page.skip === 0 &&
        userReportedTable.expandedRows.length === 0
      ) {
        dispatch(getMoreUserReportedGroupedEmails({ config: apiParams }))
        dispatch(getUserReportedTopReportersStats({ accessTokenId }))
      }
    }, 30000)

    return () => {
      clearInterval(refreshInterval)
    }
  }, [
    dispatch,
    apiParams,
    userReportedTable.expandedRows,
    userReportedTable.page.skip,
    isGetUserReportedGroupedEmailsSuccess,
    isUserReportedLoading,
    accessTokenId
  ])

  // Pagination
  useEffect(() => {
    if (isGetUserReportedGroupedEmailsSuccess && !userReportedEmailsData[userReportedTable.page.skip]) {
      dispatch(getMoreUserReportedGroupedEmails({ config: apiParams }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReportedTable.page.skip])

  // Sort
  useEffect(() => {
    if (isGetUserReportedGroupedEmailsSuccess) {
      dispatch(getMoreUserReportedGroupedEmails({ config: apiParams, resetResult: true }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReportedTable.sort])

  // Reload after dismissing a row
  useEffect(() => {
    if (isUpdateUserReportedStateSuccess) {
      dispatch(getMoreUserReportedGroupedEmails({ config: apiParams }))
    }
  }, [apiParams, dispatch, isUpdateUserReportedStateSuccess])

  // Handle error message
  useEffect(
    () => {
      setState({ errorMsg: '' })
      if (isGetUserReportedGroupedEmailsFailed || isGetMoreUserReportedGroupedEmailsFailed) {
        setState({ errorMsg: formatMessage('labels.page_error') })
      }
      if (updateUserReportedErrorMsg) {
        setState({ errorMsg: globalFormatMessage(updateUserReportedErrorMsg) })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isGetUserReportedGroupedEmailsFailed, isGetMoreUserReportedGroupedEmailsFailed, updateUserReportedErrorMsg]
  )

  const basicIncidentResponse = useMemo(() => {
    return productLib.getForensicsSerialBundleForAccessToken(accessTokenId) === config.BUNDLES.BUNDLE1
  }, [accessTokenId, productLib])

  const getMaliciousTooltipMessage: any = useCallback(
    (showAttach: boolean, showIntent: boolean) => {
      if (showIntent && !showAttach) {
        return formatMessage('maliciousTooltip.maliciousURL')
      }
      if (!showIntent && showAttach) {
        return formatMessage('maliciousTooltip.maliciousAttachment')
      }
      if (showIntent && showAttach) {
        return formatMessage('maliciousTooltip.maliciousAttachmentAndURL')
      }
      return undefined
    },
    [formatMessage]
  )

  const populateGroupedItems: any = useCallback(
    (items: any[]) => {
      return items
        .filter((item: any) => Object.keys(item).length)
        .map((item: any) => {
          let expandField = !userReportedTable.collapsedGroups.includes(`${item.field}/${item.value}`)
          if (item.emailId) {
            expandField = userReportedTable.expandedRows.includes(item.emailId)
          }

          return {
            ...item,
            [EXPAND_FIELD]: expandField,
            ...(item.aggregates && {
              ...(item.field === userReportedTable.GRID_COLUMNS.SENDER_EMAIL && {
                value: item.value
              }),
              items: populateGroupedItems(item.items)
            })
          }
        })
    },
    [userReportedTable.collapsedGroups, userReportedTable.expandedRows, userReportedTable.GRID_COLUMNS.SENDER_EMAIL]
  )

  const gridData = useMemo(() => {
    const { page } = userReportedTable
    const data = process(
      (userReportedEmailsData as any)
        .filter((reported: UserSubmittedEmailGrouped) => {
          if (reported) {
            // filters out dismissed rows
            return !state.rowsDismissed.includes(reported.emailId)
          }
          // returns empty placeholders (i.e. skipping pages)
          return reported === undefined
        })
        .slice(page.skip, page.skip + page.take)
        .reduce(
          (
            all: UserSubmittedEmailGrouped[],
            userSubmittedEmail: UserSubmittedEmailGrouped & { [key: string]: any[] }
          ) => {
            if (!userSubmittedEmail) {
              return [...all, {}]
            }

            // dismissing can cause duplicates
            // this checks that the emailId hasn't already been added to the data array
            if (!all.some((reported: UserSubmittedEmailGrouped) => reported.emailId === userSubmittedEmail.emailId)) {
              const showAttachmentWarning = userSubmittedEmail.allAttachmentResults.some(allAttach =>
                allAttach.attachmentResults.some((ar: { clean: boolean }) => !ar.clean)
              )
              const emailInfo = emails.emailInfoList[userSubmittedEmail.emailId]
              const emailDetails = emailInfo && {
                attachments: emailInfo.attachments,
                body: emailInfo.body.content,
                bodyMimeType: 'text/html',
                date: emailInfo.created,
                from: {
                  email: emailInfo.sender.address,
                  displayName: emailInfo?.sender.name
                },
                headers: emailInfo['internet-message-headers'],
                subject: emailInfo.subject,
                to: {
                  email: collectRecipients(emailInfo.recipients || []),
                  displayName: ''
                }
              }
              return [
                ...all,
                {
                  emailId: userSubmittedEmail.emailId,
                  expandedRowContent: {
                    emailDetailDialogConfig: { ...emailDetailDialogConfig, emailDetails }
                  },
                  formattedDate: datetime.formatDate(
                    userSubmittedEmail.lastReported,
                    config.DATETIME.DEFAULT_DATE_WITH_TIME_FORMAT
                  ),
                  isPhishline: userSubmittedEmail.isPhishline,
                  createIncidentDisabled:
                    basicIncidentResponse || (satCampaignsIgnored && userSubmittedEmail.isPhishline),
                  lastReported: userSubmittedEmail.lastReported,
                  maliciousTooltipMessage: getMaliciousTooltipMessage(
                    showAttachmentWarning,
                    userSubmittedEmail.allIntentResults.length > 0
                  ),
                  maxMatchedCount: userSubmittedEmail.maxMatchedCount,
                  messageIds: userSubmittedEmail.messageIds,
                  numberOfUserReported: userSubmittedEmail.recipients.length,
                  recipients: userSubmittedEmail.recipients,
                  searchCriteria: userSubmittedEmail.searchCriteria,
                  senderEmail: userSubmittedEmail.sender.address,
                  subject: userSubmittedEmail.subject,
                  sender: userSubmittedEmail.sender.address
                }
              ]
            }

            return [...all, {}]
          },
          []
        )
        .filter((item: any) => Object.keys(item).length),
      { sort: userReportedTable.sort }
    )
    return {
      total: totalCount,
      data: populateGroupedItems(data.data)
    }
    // don't want to run this on state.rowsDismissed change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userReportedTable,
    userReportedEmailsData,
    totalCount,
    emails.emailInfoList,
    populateGroupedItems,
    getMaliciousTooltipMessage
  ])

  const onCreateIncident = useCallback(
    (searchParams: SubmittedSearchCriteria) => {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.INCIDENT_WIZARD_NEW_INCIDENT_STARTED, {
        accessTokenId,
        page: 'user-reported/emails'
      })

      setState({
        newIncidentSearchCriteria: {
          attachmentName: searchParams.attachment,
          sender: {
            address: searchParams.senderEmail,
            name: searchParams.senderName
          },
          subject: searchParams.subject
        }
      })
    },
    [accessTokenId]
  )

  const onDismissUserReported = useCallback(
    (emailId: string, messageIds: string[]) => {
      dispatch(updateUserReportedState({ accessTokenId, messageIds, state: DISMISSED_STATE }))
      setState({ rowsDismissed: [...state.rowsDismissed, emailId] })
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.USER_REPORTED_DISMISS_MESSAGE, {
        accessTokenId,
        page: 'user-reported'
      })
    },
    [accessTokenId, dispatch, state.rowsDismissed]
  )

  const onExpandChange = useCallback(
    (e: any) => {
      if (e.dataItem.emailId) {
        if (
          !isEmailInfoLoading &&
          (!isEmailInfoFailed || (isEmailInfoFailed && e.dataItem.emailId !== emails.lastFailedEmailId)) &&
          !emails.emailInfoList[e.dataItem.emailId] &&
          e.value &&
          e.dataItem.emailId !== emails.lastFailedEmailId
        ) {
          emailDetailDialogActions.onOpen(e.dataItem)
        }
        dispatch(
          dataTableUpdate({
            config: { expandedRows: toggleInArray(userReportedTable.expandedRows, e.dataItem.emailId) }
          })
        )
      } else {
        dispatch(
          dataTableUpdate({
            config: {
              collapsedGroups: toggleInArray(
                userReportedTable.collapsedGroups,
                `${e.dataItem.field}/${e.dataItem.value}`
              )
            }
          })
        )
      }
    },
    [
      emails.lastFailedEmailId,
      emails.emailInfoList,
      emailDetailDialogActions,
      userReportedTable.expandedRows,
      userReportedTable.collapsedGroups,
      dispatch,
      isEmailInfoFailed,
      isEmailInfoLoading
    ]
  )

  const onToggleViewState = useCallback(() => {
    if (state.reportedState === SUBMITTED_STATE) {
      setState({ reportedState: DISMISSED_STATE })
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.USER_REPORTED_VIEWED_DISMISSED, {
        accessTokenId,
        page: 'user-reported'
      })
    } else {
      setState({ reportedState: SUBMITTED_STATE })
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.USER_REPORTED_VIEW_SUBMITTED, {
        accessTokenId,
        page: 'user-reported'
      })
    }
  }, [accessTokenId, state.reportedState])

  const topReportersDashboardConfig = useMemo(() => {
    // Need to deep clone the store data, Kendo is attempting to write to a read only object
    const clonedUserReportedUserStats = cloneDeep(stats.userReportedUserStats) || ([] as any[])

    // Used to skip duplicate valueAxis labels if datapoint is less than 5
    const labelSkip = clonedUserReportedUserStats.filter(
      (chartSeries: { reported: number }) => chartSeries.reported >= 5
    )

    return {
      chartConfig: {
        chartStyle: { height: 250 },
        customTooltip: MultiSeriesTooltip,
        chartCategoryAxisItemProps: {
          labels: {
            color: colors.humpbackGray,
            content: (e: { value: string }) => (e.value.length > 20 ? `${e.value.substring(0, 20)}...` : e.value),
            font: '400 14px Roboto, sans-serif'
          },
          majorGridLines: { visible: false }
        },
        chartSeriesItems: [
          {
            chartData: Object.values(clonedUserReportedUserStats).slice(
              0,
              Object.values(clonedUserReportedUserStats).length < 5
                ? Object.values(clonedUserReportedUserStats).length
                : 5
            ),
            chartSeriesItemProps: {
              categoryField: 'user',
              color: colors.chartBarracudaGreen,
              field: 'remediated',
              line: { color: colors.chartBarracudaGreen, width: 2 },
              stack: true,
              name: formatMessage('charts.topReporters.tooltip_remediated'),
              visibleInLegend: false,
              gap: clonedUserReportedUserStats.length === 1 ? 10 : undefined,
              type: 'bar'
            },
            chartSeriesItemTooltipProps: {
              formatTitle: (category: string) => category
            }
          },
          {
            chartData: Object.values(clonedUserReportedUserStats).slice(
              0,
              Object.values(clonedUserReportedUserStats).length < 5
                ? Object.values(clonedUserReportedUserStats).length
                : 5
            ),
            chartSeriesItemProps: {
              categoryField: 'user',
              color: colors.chartBarracudaOrange,
              field: 'dismissed',
              line: { color: colors.chartBarracudaOrange, width: 2 },
              name: formatMessage('charts.topReporters.tooltip_dismissed'),
              visibleInLegend: false,
              type: 'bar'
            },
            chartSeriesItemTooltipProps: {
              formatTitle: (category: string) => category
            }
          },
          {
            chartData: Object.values(clonedUserReportedUserStats).slice(
              0,
              Object.values(clonedUserReportedUserStats).length < 5
                ? Object.values(clonedUserReportedUserStats).length
                : 5
            ),
            chartSeriesItemProps: {
              categoryField: 'user',
              color: colors.chartBarracudaLightBlue,
              field: 'submitted',
              line: { color: colors.chartBarracudaLightBlue, width: 2 },
              name: formatMessage('charts.topReporters.tooltip_submitted'),
              visibleInLegend: false,
              type: 'bar'
            },
            chartSeriesItemTooltipProps: {
              formatTitle: (category: string) => category
            }
          }
        ],
        chartValueAxisItemProps: {
          labels: {
            format: 'MMMM',
            font: '600 12px Roboto, sans-serif',
            color: colors.humpbackGray,
            step: labelSkip.length > 0 ? 0 : 5
          }
        }
      },
      chartWrapperConfig: {
        chartData: Object.values(clonedUserReportedUserStats).slice(
          0,
          Object.values(clonedUserReportedUserStats).length < 5 ? Object.values(clonedUserReportedUserStats).length : 5
        ),
        chartLoading: isUserReportedLoading,
        chartTitle: formatMessage('charts.topReporters.title'),
        noDataText: formatMessage('charts.topReporters.noData')
      }
    }
  }, [stats.userReportedUserStats, isUserReportedLoading, formatMessage])

  const expandConfig = useMemo(() => {
    return {
      detail: (cellProps: any) => {
        if (!cellProps.dataItem.emailId) {
          return null
        }
        return (
          <>
            {isEmailInfoLoading && (
              <div>
                <LinearProgress />
              </div>
            )}
            <GridDetailRow dataItem={{}} dataIndex={0} />
            {!isEmailInfoLoading && emails.emailInfoList[cellProps.dataItem.emailId] && (
              <div>
                <EmailDetails
                  data={cellProps.dataItem.expandedRowContent.emailDetailDialogConfig}
                  hideReplyTo
                  isExpandRow
                  isGroupedRecipients
                  showThreatTab
                />
              </div>
            )}
          </>
        )
      },
      expandField: EXPAND_FIELD,
      onExpandChange
    }
  }, [emails.emailInfoList, isEmailInfoLoading, onExpandChange])

  const newIncidentDialogConfig = useMemo(() => {
    return {
      incidentDetailsSource: IncidentDetailsSource.userReported,
      isOpened: !!state.newIncidentSearchCriteria,
      ...(state.newIncidentSearchCriteria && {
        emailInfo: state.newIncidentSearchCriteria
      }),
      country: '',
      onClose: () => {
        setState({ newIncidentSearchCriteria: null })
      },
      onSuccess: () => {
        dispatch(getMoreUserReportedGroupedEmails({ config: apiParams, resetResult: true }))
      }
    }
  }, [apiParams, dispatch, state.newIncidentSearchCriteria])

  const pageConfig = useMemo(() => {
    return {
      ...userReportedTable.page,
      total: totalCount,
      onPageChange: (e: any) => {
        dispatch(dataTableUpdate({ config: { page: e.page as { skip: number; take: number } } }))
      }
    }
  }, [dispatch, totalCount, userReportedTable.page])

  const sortConfig = useMemo(() => {
    return {
      sortable: {
        allowUnsort: false
      },
      sort: userReportedTable.sort,
      onSortChange: (e: any) => {
        dispatch(dataTableUpdate({ config: { sort: e.sort as SortDescriptor[] } }))
      }
    }
  }, [dispatch, userReportedTable.sort])

  const alertConfig = useMemo(() => {
    return {
      alertContent: state.errorMsg,
      closeAction: () => {
        dispatch(resetUserReportedFailures())
      },
      pageAlert: true,
      showClose: true
    }
  }, [dispatch, state.errorMsg])

  // TODO: Remove this after BNFIR-4168 test
  const runPowershellzz = useCallback(() => {
    dispatch(runPowershell({ payload: 'test ' }))
  }, [dispatch])

  return useMemo(
    () => [
      {
        alertConfig,
        basicIncidentResponse,
        columnsConfig: userReportedTable.columnsConfig,
        expandConfig,
        GRID_COLUMNS: userReportedTable.GRID_COLUMNS,
        gridData,
        hideCTA: cookies[USER_REPORTED_CTA_COOKIE] === 'true',
        isDismissRowLoading: isUpdateUserReportedStateLoading,
        isMoreResultsLoading: isGetMoreUserReportedGroupedEmailsLoading,
        isPageInProgress: isUserReportedLoading,
        newIncidentDialogConfig,
        onCreateIncident,
        onDismissUserReported,
        onToggleViewState,
        pageConfig,
        rowsDismissed: state.rowsDismissed,
        showErrorAlert: !!state.errorMsg,
        sortConfig,
        topReportersDashboardConfig,
        viewingState: state.reportedState,
        // TODO: Remove this after BNFIR-4168 test
        runPowershellzz
      }
    ],
    [
      alertConfig,
      basicIncidentResponse,
      cookies,
      expandConfig,
      gridData,
      isGetMoreUserReportedGroupedEmailsLoading,
      isUpdateUserReportedStateLoading,
      isUserReportedLoading,
      newIncidentDialogConfig,
      onCreateIncident,
      onDismissUserReported,
      onToggleViewState,
      pageConfig,
      sortConfig,
      state.errorMsg,
      state.reportedState,
      state.rowsDismissed,
      topReportersDashboardConfig,
      userReportedTable,
      // TODO: Remove this after BNFIR-4168 test
      runPowershellzz
    ]
  )
}
