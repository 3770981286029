import React, { useMemo } from 'react'

import useSignupLogic from 'fir/components/pages/signup/useSigninSignupLogic'

const SigninSignup: React.FC = () => {
  useSignupLogic()

  return useMemo(() => {
    return null
  }, [])
}

export default SigninSignup
