import { createSlice } from '@reduxjs/toolkit'
import {
  UserReportedEmails,
  UserReportedGroupedEmailsResponse,
  UserSubmittedEmailGrouped
} from 'fir/redux/types/UserReported'
import insertToArray from 'global/lib/insertToArray'
import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'global/redux/toolkit/api'
import {
  getMoreUserReportedGroupedEmails,
  getUserReportedGroupedEmails,
  updateUserReportedState,
  runPowershell
} from './userReportedApiThunks'

export interface UserReportedState {
  getUserReportedGroupedEmailsAPIStatus: ApiStatus
  getMoreUserReportedGroupedEmailsAPIStatus: ApiStatus
  updateUserReportedStateAPIStatus: ApiStatus
  userReportedEmails: UserReportedEmails
}

// Initial state
export const INITIAL_STATE: UserReportedState = {
  getUserReportedGroupedEmailsAPIStatus: inIdle,
  getMoreUserReportedGroupedEmailsAPIStatus: inIdle,
  updateUserReportedStateAPIStatus: inIdle,
  userReportedEmails: { data: [] as UserSubmittedEmailGrouped[], totalCount: 0 }
}

/* eslint-disable no-param-reassign */
export const userReportedSlice = createSlice({
  name: 'USER_REPORTED',
  initialState: INITIAL_STATE,
  // reducers object creates an action and executes the reducer function
  reducers: {
    resetUserReportedFailures: state => {
      state.getUserReportedGroupedEmailsAPIStatus = INITIAL_STATE.getUserReportedGroupedEmailsAPIStatus
      state.getMoreUserReportedGroupedEmailsAPIStatus = INITIAL_STATE.getMoreUserReportedGroupedEmailsAPIStatus
      state.updateUserReportedStateAPIStatus = INITIAL_STATE.updateUserReportedStateAPIStatus
    },
    resetUserReportedGroupedEmails: state => {
      state.getUserReportedGroupedEmailsAPIStatus = INITIAL_STATE.getUserReportedGroupedEmailsAPIStatus
      state.getMoreUserReportedGroupedEmailsAPIStatus = INITIAL_STATE.getMoreUserReportedGroupedEmailsAPIStatus
      state.userReportedEmails = INITIAL_STATE.userReportedEmails
    }
  },
  // extraReducers do not create an action but executes the reducer function
  extraReducers: builder => {
    builder
      .addCase(getUserReportedGroupedEmails.pending, state => {
        state.userReportedEmails = { ...INITIAL_STATE.userReportedEmails }
        state.getUserReportedGroupedEmailsAPIStatus = inProgress
      })
      .addCase(getUserReportedGroupedEmails.fulfilled, (state, action) => {
        state.getUserReportedGroupedEmailsAPIStatus = successResponse
        state.userReportedEmails = (action.payload as UserReportedGroupedEmailsResponse).userReportedEmails
      })
      .addCase(getUserReportedGroupedEmails.rejected, (state, action) => {
        state.getUserReportedGroupedEmailsAPIStatus = failedResponse(action.payload as string)
      })
      .addCase(getMoreUserReportedGroupedEmails.pending, (state, action) => {
        state.getMoreUserReportedGroupedEmailsAPIStatus = inProgress
        if (action.meta.arg.resetResult) {
          state.userReportedEmails = {
            ...state.userReportedEmails,
            data: []
          }
        }
      })
      .addCase(getMoreUserReportedGroupedEmails.fulfilled, (state, action) => {
        state.getMoreUserReportedGroupedEmailsAPIStatus = successResponse
        state.userReportedEmails = {
          ...(action.payload as UserReportedGroupedEmailsResponse).userReportedEmails,
          data: insertToArray(
            state.userReportedEmails.data,
            ((action.payload as UserReportedGroupedEmailsResponse).userReportedEmails || {}).data || [],
            (action.payload as UserReportedGroupedEmailsResponse).skip || 0
          )
        }
      })
      .addCase(getMoreUserReportedGroupedEmails.rejected, (state, action) => {
        state.getMoreUserReportedGroupedEmailsAPIStatus = failedResponse(action.payload as string)
      })
      .addCase(updateUserReportedState.pending, state => {
        state.updateUserReportedStateAPIStatus = inProgress
      })
      .addCase(updateUserReportedState.fulfilled, state => {
        state.updateUserReportedStateAPIStatus = successResponse
      })
      .addCase(updateUserReportedState.rejected, (state, action) => {
        state.updateUserReportedStateAPIStatus = failedResponse(action.payload as string)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { resetUserReportedFailures, resetUserReportedGroupedEmails } = userReportedSlice.actions

// export { getUserReportedGroupedEmails, getMoreUserReportedGroupedEmails, updateUserReportedState }
// TODO: Remove this after BNFIR-4168 test
export { getUserReportedGroupedEmails, getMoreUserReportedGroupedEmails, updateUserReportedState, runPowershell }

export default userReportedSlice.reducer
