import { createAsyncThunk } from '@reduxjs/toolkit'

import apiRoutes from 'fir/lib/api/apiRoutes'
import { RootState } from 'fir/redux/toolkit/store'
import { UserReportedApiConfig } from 'fir/redux/types/UserReported'

import restClient, { validateApiError } from 'global/lib/api/restClient'
import { config as globalConfig } from 'global/lib/config'

export interface UpdateUserReportedState {
  accessTokenId: string
  messageIds: string[]
  state: string
}

export interface GetUserReportedGroupedEmails {
  resetResult?: boolean
  config: UserReportedApiConfig
}

export async function doGetUserReportedGroupedEmails(payload: GetUserReportedGroupedEmails, { rejectWithValue }: any) {
  const { config } = payload

  try {
    const resp = await restClient(apiRoutes.USER_REPORTED_GROUPED_SEARCH, {
      data: { ...config }
    })
    const respData = {
      userReportedEmails: {
        data: resp.data.emails.content,
        totalCount: resp.data.emails.totalCount
      },
      skip: (config.query.page ? config.query.page - 1 : 0) * config.query.limit
    }
    return respData
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
}

export const getUserReportedGroupedEmails = createAsyncThunk(
  'USER_REPORTED/getUserReportedGroupedEmails',
  doGetUserReportedGroupedEmails
)
export const getMoreUserReportedGroupedEmails = createAsyncThunk(
  'USER_REPORTED/getMoreUserReportedGroupedEmails',
  doGetUserReportedGroupedEmails
)

export const updateUserReportedState = createAsyncThunk(
  'USER_REPORTED/updateUserReportedState',
  async function doUpdateUserReportedState(payload: UpdateUserReportedState, { getState, rejectWithValue }) {
    const accessTokenId = payload.accessTokenId || ''
    const messageIds = payload.messageIds || []
    const userReportedState = payload.state || null
    const state = getState() as RootState

    try {
      // show demo error if using demo token
      if (state.user.isFirDemoUser) {
        return rejectWithValue(validateApiError({ data: { error: globalConfig.API_ERROR.DEMO_ERROR.FORENSICS } }))
      }
      await restClient(apiRoutes.UPDATE_USER_REPORTED_EMAILS_STATE, {
        data: { accessTokenId, messageIds, state: userReportedState }
      })
      return ''
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

// TODO: Remove this after BNFIR-4168 test
export const runPowershell = createAsyncThunk('USER_REPORTED/runPowershell', async function dorunPowershell(
  payload: any,
  { rejectWithValue }
) {
  try {
    await restClient(apiRoutes.RUN_POWERSHELL, {})
    return ''
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})
