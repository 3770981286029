import { reduxStore } from 'global/lib/reduxStore'
import featureLib from 'global/lib/feature/feature'
import * as configurationLib from 'global/lib/configuration/configuration'

import { Account } from 'global/types/api/accountType'

export interface CloudConnectStore {
  hasScanTypeSelection: boolean
  accounts: Account[]
  account: Account
}

export function cloudConnectStore(): CloudConnectStore {
  return reduxStore.getState().cloudConnect
}

export function showScanTypeSelection(): boolean {
  const cloudConnect = cloudConnectStore()

  return (
    cloudConnect.hasScanTypeSelection &&
    (featureLib.hasSentinelFeatureForAnyAccessToken() || featureLib.hasETSReadOnlyForAnyAccessToken())
  )
}

export function showBccAccountSelection(): boolean {
  const cloudConnect = cloudConnectStore()

  return !!cloudConnect.accounts && cloudConnect.accounts.length > 1
}

export function connect(
  provider: string,
  source: string,
  scanType: string,
  accountId: string | null = null,
  isReadOnly = false
): void {
  window.open(configurationLib.getConnectOffice365Uri(provider, scanType, source, accountId, isReadOnly))
}

export function reconnect(
  provider: string,
  source: string,
  scanType: string,
  accountId: string,
  accessTokenId: string
): void {
  window.open(configurationLib.getReconnectOffice365Uri(provider, scanType, source, accountId, accessTokenId))
}

export function bccSignin(scanType: string) {
  window.open(configurationLib.getBccSigninUri(scanType), '_self')
}

export function resetPasswordATO(
  provider: string,
  source: string,
  scanType: string,
  accountId: string,
  accessTokenId: string,
  userPrincipalName: string
): void {
  window.open(
    configurationLib.getPasswordResetOffice365Uri(
      provider,
      source,
      scanType,
      accountId,
      accessTokenId,
      userPrincipalName
    )
  )
}
