import { useEffect, useCallback } from 'react'

import { resetLoggedOut } from 'global/redux/features/app/appSlice'
import { useAppDispatch } from 'global/redux/toolkit/hooks'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import * as cloudLib from 'global/lib/cloud/cloud'
import { config } from 'global/lib/config'

import { setError } from 'global/redux/features/auth/authSlice'

import { User } from 'global/types/api/userType'

import gotoReportPage from 'fir/lib/gotoReportPage'

export interface SigninSignupLogicProps {
  isLoading: boolean
}

/* eslint-disable @typescript-eslint/naming-convention */
declare global {
  interface Window {
    __completeBccSignin__: (user: User) => void
    __failedBccSignin__: (error: any) => void
  }
}
/* eslint-enabled @typescript-eslint/naming-convention */

export default function useSignupLogic(): void {
  const dispatch = useAppDispatch()

  // signin flow
  const onCompleteBccSignin = useCallback((userData: User) => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.SIGNUP_SIGNIN_SUCCESSFUL, {
      url: window.location.href,
      email: userData.email
    })
    gotoReportPage({ user: userData })
  }, [])

  const onFailedBccSignin = useCallback(
    (data: any) => {
      const errorMessage = data.error.includes('UserDoesNotExist') ? 'user_does_not_exist' : 'default'

      dispatch(setError(errorMessage))
    },
    [dispatch]
  )

  useEffect(() => {
    dispatch(resetLoggedOut())
    /* eslint-disable no-underscore-dangle */
    window.__completeBccSignin__ = onCompleteBccSignin
    window.__failedBccSignin__ = onFailedBccSignin
    /* eslint-enable no-underscore-dangle */

    cloudLib.bccSignin(config.SCAN_TYPES.FORENSICS)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
